import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "WebLayout" */ "@/layouts/WebLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
        meta: {
          requiresAuth: false,
          title: "Home - Municipalidad de Quilpué"
        }
      },
      {
        path: "/actividades",
        name: "Actividades",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Actividades/GeneralView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Actividades - Municipalidad de Quilpué"
        }
      },
      {
        path: "/actividades/categoria/:nombre",
        name: "CategoriaActividad",
        component: () =>
          import(
            /* webpackChunkName: "CategoriaActividad" */ "@/views/Actividades/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticias"
        }
      },
      {
        path: "/actividades/publicacion/:nombre",
        name: "PublicacionAct",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Actividades/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Actividad"
        }
      },
      {
        path: "/noticias",
        name: "Noticias",
        component: () =>
          import(
            /* webpackChunkName: "noticias" */ "@/views/Noticias/NoticiasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Noticias - Municipalidad de Quilpué"
        }
      },
      {
        path: "/noticias/publicacion/:nombre",
        name: "Publicacion",
        component: () =>
          import(
            /* webpackChunkName: "Publicacion" */ "@/views/Noticias/PublicacionView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia"
        }
      },
      {
        path: "/contenido/noticias/preview/:nombre",
        name: "PublicacionPreview",
        component: () =>
          import(
            /* webpackChunkName: "PublicacionPreview" */ "@/views/Noticias/PublicacionPreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticia Preview"
        }
      },
      {
        path: "/noticias/categoria/:nombre",
        name: "Categoria",
        component: () =>
          import(
            /* webpackChunkName: "Categoria" */ "@/views/Noticias/CategoriaView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Noticias"
        }
      },
      {
        path: "/adopcion",
        name: "Adopciones",
        component: () =>
          import(
            /* webpackChunkName: "adopciones" */ "@/views/Adopciones/GeneralView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Adopciones - Municipalidad de Quilpué"
        }
      },
      {
        path: "/adopcion/:id/:nombre",
        name: "DetalleAdopcion",
        component: () =>
          import(
            /* webpackChunkName: "detalleadopcion" */ "@/views/Adopciones/DetalleAdopcionView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Adopciones - Municipalidad de Quilpué"
        }
      },
      {
        path: "/:nombre",
        name: "Pagina",
        component: () =>
          import(
            /* webpackChunkName: "Pagina" */ "@/views/Pagina/GenericoView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Municipalidad de Quilpué"
        }
      },
      {
        path: "/contenido/pagina/preview/:nombre",
        name: "PaginaPreview",
        component: () =>
          import(
            /* webpackChunkName: "PaginaPreview" */ "@/views/Pagina/PreviewView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Preview Página"
        }
      },
      {
        path: "/municipio/servicios",
        name: "Servicios",
        component: () =>
          import(
            /* webpackChunkName: "Servicios" */ "@/views/Servicios/ServiciosView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Pagos y Servicios- Municipalidad de Quilpué"
        }
      },
      {
        path: "/formulario/contacto/:nombre/:id",
        name: "FormularioContacto",
        component: () =>
          import(
            /* webpackChunkName: "Avisos" */ "@/views/Formularios/FormularioGeneralView.vue"
          ),
        meta: {
          notLogged: false,
          requiresAuth: false,
          title: "Formulario de Contacto - Municipalidad de Quilpué"
        }
      },
      {
        path: "/municipio/oficinas-y-telefonos-municipales",
        name: "Telefonos",
        component: () =>
          import(
            /* webpackChunkName: "telefonosMuni" */ "@/views/Web/TelefonosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Oficinas y Teléfonos - Municipalidad de Quilpué"
        }
      },
      {
        path: "/municipio/ofertas",
        beforeEnter(to, from, next) {
          window.location.href = "https://atencion.quilpue.cl/empleos";
        }
      },
      // {
      //   path: "/municipio/ofertas",
      //   name: "OfertasOmil",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "oferta" */ "@/views/Web/OfertasOmilView.vue"
      //     ),
      //   meta: {
      //     title: "Bolsa de Empleo - Municipalidad de Quilpué"
      //   }
      // },
      {
        path: "/encuesta/:nombre",
        name: "Encuesta",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Encuesta/EncuestaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/municipio/concejo",
        name: "Concejo",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ConcejoView.vue"
          ),
        meta: {
          title: "Concejo Municipal - Municipalidad de Quilpué"
        }
      },
      {
        path: "/municipio/concursos-publicos",
        name: "ConcursosPublicos",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ConcursosPublicosView.vue"
          ),
        meta: {
          title: "ConcursosPublicos - Municipalidad de Quilpué"
        }
      },
      {
        path: "/municipio/actas-concejo",
        name: "ActasConcejo",
        component: () =>
          import(
            /* webpackChunkName: "concejo" */ "@/views/Municipalidad/ActasConcejoView.vue"
          ),
        meta: {
          title: "Actas de Concejo - Municipalidad de Quilpué"
        }
      },
      {
        path: "/municipio/ordenanzas",
        name: "Ordenanzas",
        component: () =>
          import(
            /* webpackChunkName: "ordenanzas" */ "@/views/Municipalidad/OrdenanzasView.vue"
          ),
        meta: {
          title: "Ordenanzas - Municipalidad de Quilpué"
        }
      },
      {
        path: "/municipio/cosoc",
        name: "Cosoc",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Municipalidad/CosocView.vue"
          ),
        meta: {
          title:
            "Consejo Comunal de Organizaciones de la Sociedad Civil - Municipalidad de Quilpué"
        }
      },
      {
        path: "/formulario/medio-ambiente",
        name: "MedioAmbiente",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Formularios/FormularioInscripcionView.vue"
          ),
        meta: {
          title:
            "Inscripción Seminario Día Mundial del Medio Ambiente - Municipalidad de Quilpué"
        }
      },
      {
        path: "/formulario/gas",
        name: "FormularioGas",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Formularios/FormularioInscripcionGasView.vue"
          ),
        meta: {
          title:
            "Inscripción Seminario Día Mundial del Medio Ambiente - Municipalidad de Quilpué"
        }
      },
      {
        path: "/resultados/navidad",
        name: "ResultadosNavidad",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Resultados/ResultadosNavidadView.vue"
          ),
        meta: {
          title: "Resultados Feria Navideña 2022 - Municipalidad de Quilpué"
        }
      },
      {
        path: "/formulario/postulacion",
        name: "Postulacion",
        component: () =>
          import(
            /* webpackChunkName: "cosoc" */ "@/views/Formularios/FormularioPostulacionView.vue"
          ),
        meta: {
          title: "Trabaja con Nosotros - Municipalidad de Quilpué"
        }
      },
      {
        path: "/municipio/galerias",
        name: "Galerias",
        component: () =>
          import(
            /* webpackChunkName: "galerias" */ "@/views/Galeria/GaleriaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Galerías - Municipalidad de Quilpué"
        }
      },
      {
        path: "/galeria/:nombre",
        name: "DetalleGaleria",
        component: () =>
          import(
            /* webpackChunkName: "detallegaleria" */ "@/views/Galeria/DetalleGaleriaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Galeria - Municipalidad de Quilpué"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
